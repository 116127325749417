import React from 'react';

import * as styles from './index.module.css';

const InlineBlockWrapper = ({ className, children }) => {
  return (
    <div
      className={className ? `${styles['root']} ${className}` : styles['root']}
    >
      {children}
    </div>
  );
};

export default InlineBlockWrapper;
