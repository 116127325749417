import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import PageDefault from '../../components/PageDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import * as styles from './index.module.css';

const CallbackTemplate = () => {
  return (
    <PublicRoot>
      <PageDefault>
        <div className={styles['spinnerWithLabelWrapper']}>
          <div className={styles['spinnerWithLabel']}>
            <InlineBlockWrapper
              className={styles['spinnerWithLabelSpinnerWrapper']}
            >
              <div className={styles['spinnerWithLabelSpinnerRoot']}>
                <div className={styles['spinnerWithLabelSpinner']}></div>
              </div>
            </InlineBlockWrapper>
            <InlineBlockWrapper>
              <span className={styles['spinnerWithLabelLabel']}>
                Loading...
              </span>
            </InlineBlockWrapper>
          </div>
        </div>
      </PageDefault>
    </PublicRoot>
  );
};

export default CallbackTemplate;
